<template>
	<section class="auth">
		<div class="columns">
			<div v-if="!isMobile" class="column is-two-fifths is-hidden-mobile p-0">
				<div class="auth__image">
					<video autoplay muted loop>
						<source :src="video" type="video/mp4">
					</video>
				</div>
			</div>
			<div class="column auth__content">
				<slot>
					<router-view />
				</slot>
			</div>
		</div>
	</section>
</template>

<script>
import video from '../assets/images/background/login.mp4'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Layout',
	mixins: [responsive],
	data() {
		return {
			video: video
		}
	},
    metaInfo () {
      return {
        title: this.$tc(this.$route.meta.title)
      }
    }
}
</script>
